.root {
    position: absolute;
    background: #fff;
    top: 40px;
    width: 107px;
    border: 1px solid;
    border-radius: 3px;
    z-index: 4;
    .widthOptionWrapper {
        padding: 14px 5px;
        border-bottom: 1px solid;
        transition: .3s;
        cursor: pointer;
        &:last-of-type {
            border-bottom: 0px;
        }
        &:hover, &.selected {
            background: #e4e4e4;
        }
        .widthOption {
            background: #000;
            border-radius: 2.5px;
        }
    }
}