.canvas {
    transform-origin: 0 60px;
    cursor: crosshair;
    width: 100%;
    height: 100%;
    line,
    path {
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
    }        
}