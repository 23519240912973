.root {
    padding: 12px;
    display: flex;
    flex-direction: column;
    height: 98%;
    padding-bottom: 0;
    .header {
        display: flex;
        margin-bottom: 12px;
        align-items: center;
        h4 {
            margin: 0;
            padding: 0;
            font-size: 2vh;
            margin-right: 14px;
            text-transform: uppercase;
        }
    }
    .canvasWrapper {
        height: 100%;
        width: 100%;
        color: white;
        font-size: 24px;
        margin: 0 auto;
        text-align: center;
        border: 1px dashed rgba(#000000, 0.2);
        border-radius: 10px;
        overflow: hidden;
        touch-action: none;
        position: relative;
        cursor: crosshair;
        .canvas {
            z-index: 1;
        }
        .highlighterCanvas {
            display: none;
            z-index: 0;
            &.visible {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                z-index: 2;
            }
        }
    }
}